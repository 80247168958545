import React from "react";
import { Container, Typography, Box, Link, Divider } from "@mui/material";

const PrivacyPolicyPage = () => (
    <Container maxWidth="md" sx={{ py: 8, }}>
        <Typography variant="h4" component="h1" gutterBottom>
            Privacy Policy
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Effective Date: 25/11/2024
        </Typography>
        <Typography paragraph>
            Welcome to Guided Path! Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our platform. By accessing or using Guided Path, you agree to the terms of this policy.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                1. Information We Collect
            </Typography>
            <Typography paragraph gutterBottom>
                We collect the following types of personal information:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li> <Bold>Personal Identification Information:</Bold> Name, email address.</li>
                <li> <Bold>Payment Information:</Bold> Details required for processing transactions.</li>
                <li> <Bold>Work Information:</Bold> Professional details relevant to the services provided.</li>
                <li> <Bold>Geolocation Data:</Bold> To offer personalized services and location-based recommendations.</li>
            </ul>

            <Typography variant="subtitle1" fontWeight="bold" gutterBottom marginTop={2}> Sensitive Data: </Typography>
            <Typography paragraph>
                We do not collect medical records, psychological assessments, or other sensitive health information.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                2. How We Use Your Information
            </Typography>
            <Typography paragraph gutterBottom>
                We use your information for the following purposes:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>To provide and improve our services.</li>
                <li>To communicate with you, including sending promotional emails.</li>
                <li>For analytics and marketing to enhance user experience and reach.</li>
            </ul>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                3. Sharing Your Information
            </Typography>
            <Typography paragraph>
                We may share your information with third-party service providers to facilitate:
            </Typography>

            <ul style={{ marginLeft: '2rem' }}>
                <li>Payment processing.</li>
                <li>Marketing and analytics.</li>
                <li>Platform maintenance and improvements.</li>
            </ul>

            <Typography paragraph paddingTop={2}>
                <Bold> Note:</Bold> Your information will <Bold>never</Bold> be sold  or disclosed to third parties for unrelated purposes.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                4. Your Rights
            </Typography>
            <Typography paragraph>
                You have the following rights regarding your personal data:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li> <Bold>Access and Update:</Bold> You can access and update your personal information through your account.</li>
                <li> <Bold>Deletion:</Bold> You may request the deletion of your personal data.</li>
                <li> <Bold>Communication Preferences:</Bold> You can opt out of promotional emails and other communications.</li>
            </ul>

            <Typography paragraph paddingTop={2}>
                To exercise your rights, contact us at <a href="mailto:privacy@guidedpath.com">privacy@guidedpath.com</a>.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                5. Data Security
            </Typography>
            <Typography paragraph>
                We implement robust measures to protect your data, including:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>Encryption of sensitive information.</li>
                <li>Firewalls to prevent unauthorized access.</li>
                <li>Access control to restrict data handling to authorized personnel.</li>
            </ul>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                6. Cookies and Tracking
            </Typography>
            <Typography paragraph>
                Guided Path uses cookies and similar tracking technologies to:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>Enhance service quality.</li>
                <li>Provide personalized experiences.</li>
                <li>Support marketing and analytics efforts.</li>
            </ul>
            <Typography paragraph paddingTop={2}>
                You can manage cookie preferences through your browser settings.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                7. Age Restrictions
            </Typography>
            <Typography paragraph>
                Our services are designed for users aged 18 and older. Young adults below 18 may use the platform with parental supervision.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                8. Global Users and Compliance
            </Typography>
            <Typography paragraph>
                Guided Path is accessible globally, and we comply with international data protection regulations, including:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>General Data Protection Regulation (GDPR).</li>
                <li>California Consumer Privacy Act (CCPA).</li>
            </ul>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                9. Data Retention
            </Typography>
            <Typography paragraph>
                We retain your personal information until you request its deletion. For deletion requests, contact <a href="mailto:privacy@guidedpath.com">privacy@guidedpath.com</a>.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                10. Contact Us
            </Typography>
            <Typography paragraph>
                For questions or concerns about this Privacy Policy or your data, please contact us at: <br />
                <Bold>Email:</Bold> <a href="mailto:privacy@guidedpath.com">privacy@guidedpath.com</a>
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography paragraph>
                By using Guided Path, you acknowledge and agree to this Privacy Policy. We may update this policy from time to time, and we encourage you to review it periodically.
            </Typography>
        </Box>
    </Container>
);

function Bold({ children }) {
    return <Typography component="span" fontWeight="bold">{children}</Typography>;
}

export default PrivacyPolicyPage;
