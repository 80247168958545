import React from "react";
import { Container, Typography, Box, Link, Divider } from "@mui/material";

const TermsOfUse = () => (
    <Container maxWidth="md" sx={{ py: 8, }}>
        <Typography variant="h4" component="h1" gutterBottom>
            Terms of Use for Guided Path
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Effective Date: 25/11/2024
        </Typography>
        <Typography variant="body1" paragraph>
            Welcome to Guided Path! By accessing or using our platform, you agree to abide by these
            Terms of Use. If you do not agree, you may not use our services. These Terms govern your
            access to and use of Guided Path, operated by BOAD OU.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                1. General Information
            </Typography>
            <Typography variant="body1" paragraph>
                Guided Path connects users with therapists, coaches, and other professionals for online
                and offline consultations. The platform is open to users aged 18 and older. Young adults
                below 18 may use the platform with parental supervision.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                2. Account Creation
            </Typography>
            <Typography paragraph>
                To access Guided Path, you must create an account by providing a valid email address,
                phone number, and password.
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                Account Rules:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>Accounts are personal and may not be shared or transferred.</li>
                <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
                <li>You must notify us immediately of any unauthorized use of your account.</li>
            </ul>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                3. Service Use
            </Typography>
            <Typography variant="body1" paragraph>
                By using Guided Path, you agree to:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>Use the platform solely for its intended purpose.</li>
                <li>Refrain from contacting coaches or other service providers off-platform.</li>
                <li>Avoid the use of abusive or foul language in communications.</li>
                <li>Respect the rights of other users and service providers.</li>
            </ul>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom marginTop={2}>
                Session Cancellation and Refunds:
            </Typography>
            Users may cancel sessions and request refunds under the following conditions:
            <ul style={{ marginLeft: '2rem' }}>
                <li>Cancellations must be made at least 24 hours before the scheduled session to qualify for a full refund.</li>
                <li>Sessions canceled less than 24 hours in advance may incur a cancellation fee.</li>
                <li>Refunds are subject to review and approval.</li>
            </ul>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                4. Payments
            </Typography>
            <Typography variant="body1" paragraph>
                Payments are processed by a third-party payment platform. By using Guided Path, you agree
                to their terms and policies, which govern payment processing, refunds, and chargebacks.
            </Typography>
            <Typography variant="body1" paragraph>
                We offer both subscription plans and pay-as-you-go options for accessing services.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                5. Content
            </Typography>
            <Typography variant="body1" paragraph>
                Guided Path includes a chat function and allows users to rate service providers. By
                submitting content, you agree to the following rules:
            </Typography>
            <ul style={{ marginLeft: '2rem' }}>
                <li>Content must not include offensive, defamatory, or illegal material.</li>
                <li>Reviews must reflect genuine experiences and not be influenced by incentives.</li>
                <li>Guided Path reserves the right to moderate, edit, or remove user-generated content.</li>
            </ul>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom marginTop={2}>
                Ownership:
            </Typography>
            <Typography variant="body1" paragraph>
                All intellectual property, including but not limited to the platform’s design, logos, and
                content, is owned by BOAD OU.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                6. Liability
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                Disclaimer:
            </Typography>
            <Typography variant="body1" paragraph>
                Guided Path provides a platform to connect users with professionals. We do not endorse,
                guarantee, or assume responsibility for the advice or services provided by professionals
                on the platform. All interactions and outcomes are solely between the user and the professional.
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom marginTop={2}>
                Limitation of Liability:
            </Typography>
            To the fullest extent permitted by law:
            <ul style={{ marginLeft: '2rem' }}>
                <li>Guided Path is not liable for any indirect, incidental, special, or consequential damages resulting from the use of the platform, including but not limited to service interruptions or data breaches.</li>
                <li>Our total liability for any claim arising out of or relating to the platform is limited to the amount you paid to use the service in the 12 months preceding the claim. </li>
            </ul>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                7. Termination
            </Typography>
            <Typography paragraph>
                We may terminate or suspend your account for:
            </Typography>
            <ul style={{ marginLeft: '2rem', paddingBottom: "1rem" }}>
                <li>Violation of these Terms of Use.</li>
                <li>Misuse of services or inappropriate behavior.</li>
                <li>Requests by you to deactivate or delete your account.</li>
            </ul>
            <Typography paragraph>
                Users can deactivate or delete their accounts by contacting <a href="mailto:support@guidedpath.com">support@guidedpath.com </a>.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                8. Governing Law
            </Typography>
            <Typography paragraph>
                These Terms are governed by the laws of the Russian Federation. Any disputes arising from these Terms will be resolved under the jurisdiction of the courts in the Russian Federation.
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                9. Contact Information
            </Typography>
            <Typography variant="body1" paragraph>
                For questions or concerns regarding these Terms, please contact us:
            </Typography>
            <Typography variant="body1">
                Email:{" "}
                <Link href="mailto:support@guidedpath.com" color="primary">
                    support@guidedpath.com
                </Link>
            </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ my: 3 }}>
            <Typography paragraph>
                By using Guided Path, you acknowledge and agree to these Terms of Use. We reserve the right to update or modify these Terms at any time. Continued use of the platform constitutes acceptance of the revised Terms.
            </Typography>
        </Box>
    </Container>
);

export default TermsOfUse;
