import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-chat-elements/dist/main.css';
import { Box, TextField, Button, LinearProgress, CircularProgress, IconButton, Menu, MenuItem, Grow, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Alert, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, Paper, Snackbar } from '@mui/material';
import { MessageList, ChatList } from 'react-chat-elements';
import { collection, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PeopleOutline, VideoCallOutlined, MoreVert, Delete } from '@mui/icons-material';
import { useStreamVideoClient } from '@stream-io/video-react-sdk';
import '@stream-io/video-react-sdk/dist/css/styles.css';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import _ from 'lodash';

import { sendMessage } from '../services/chats_service';
import UserContext from '../contexts/UserContext';
import { db } from '../firebaseConfig';
import { formatImage } from '../utils/formatImage';
import CallBar from '../components/call-layer/call-bar';
import UserSearch from '../components/user-search';
import { getUserMinInfos } from '../services/users_service';
import { upsertUsers } from '../services/call_service';
import useChatCall from '../hooks/useChatCall';


const ChatsPage = () => {
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    const [chats, setChats] = useState([]);
    const { id: paramChatId } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const [currentChat, setCurrentChat] = useState(null);

    useEffect(() => {
        const unsubscribe = onSnapshot(
            query(collection(db, 'chats'), where("members", "array-contains", user.id)),
            (snapshot) => {
                console.log("Chat update ")
                const chats = [];
                snapshot.forEach((doc) => {
                    chats.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                const sortedChats = chats.sort((a, b) => b.lastUpdate - a.lastUpdate);
                setChats(sortedChats);
                if (chats.length > 0) {
                    setCurrentChat(sortedChats.find((chat) => chat.id === paramChatId) || sortedChats[0]);
                }
                setIsLoading(false);
            }
        );

        return () => unsubscribe();
    }, [paramChatId, user.id]);

    return (
        <>
            <Helmet>
                <title> {t('chats')}</title>
            </Helmet>
            {
                isLoading
                    ? <LinearProgress />
                    : !chats.length ? (
                        <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="2rem">
                            <div style={{ fontSize: '2rem' }}>{t('No chat sessions')}</div>
                        </Box>
                    ) : (
                        <div style={styles.container} className='spacing-x-4'>

                            <div style={styles.flex}>
                                <div style={styles.conversationList}>
                                    <ConversationsList chats={chats} onClickChat={setCurrentChat} />
                                </div>
                                <div size={8} style={styles.messages}>
                                    <ChatRight chat={currentChat} user={user} t={t} />
                                </div>
                            </div>
                        </div>
                    )
            }
        </>
    );
}



ConversationsList.propTypes = {
    chats: PropTypes.array,
    onClickChat: PropTypes.func
};
function ConversationsList({ chats, onClickChat }) {
    const toRender = chats.map((chat) => ({
        ...chat,
        avatar: formatImage(chat.image, { w: 72, h: 72 }),
        alt: chat.name,
        title: chat.name,
        subtitle: chat.lastMessage,
        date: chat.lastUpdate ? new Date(chat.lastUpdate.toMillis()) : new Date(),
    }));

    return (
        <div style={styles.chatListContainer}>
            <div style={{ flex: 1, width: '100%', height: '100%' }}>
                <ChatList className="chat-list" style={styles.chatList} dataSource={toRender} onClick={onClickChat} />
            </div>
        </div>
    );
}



const ChatRight = memo(ChatRightComponent, (prevProps, nextProps) => {
    const sameChat = prevProps.chat?.id === nextProps.chat?.id;
    const sameMembers = _.isEqual(prevProps.chat?.members, nextProps.chat?.members);
    const sameCall = _.isEqual(prevProps.chat?.callInfos, nextProps.chat?.callInfos);
    return sameChat && sameMembers && sameCall;
});
ChatRightComponent.propTypes = {
    chat: PropTypes.object,
    user: PropTypes.object,
    t: PropTypes.func
};
function ChatRightComponent({ chat, t, user }) {
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [members, setMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [inputMessage, setInputMessage] = useState('');
    const { id: chatId } = chat;

    const handleTitleClick = (message) => {
        navigate(`/users/${message.senderId}`);
    };

    const sortByCreatedAt = (a, b) => a.createdAt.toMillis() - b.createdAt.toMillis();

    const processMessagesToRender = useCallback((messages) => messages
        .map((message) => ({
            ...message,
            position: message.senderId === user?.id ? 'right' : 'left',
            type: 'text',
            title: message.senderName,
            text: message?.message,
            date: new Date(message.createdAt.toMillis()),
            status: "received"
        }))
        .sort(sortByCreatedAt), [user]);

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleSend();
        }
    };

    const handleSend = () => {
        if (inputMessage.trim() !== '') {
            try {
                setMessages([...messages, { position: 'right', type: 'text', title: user.name, text: inputMessage, date: new Date(), status: 'waiting'}]);
                sendMessage(inputMessage, chat, user.id, members);
                setInputMessage('');
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    }

    useEffect(() => {
        if (!chat?.members) return () => { };

        const fetchMembers = async () => {
            setIsLoading(true);
            console.log("Fetching chat members informations");
            const membersId = chat.members;
            const members = await Promise.all(membersId.map(getUserMinInfos));
            setMembers(members);
            setIsLoading(false);
        };

        fetchMembers();
        return () => { };
    }, [chat]);

    useEffect(() => {
        if (!chatId || !members.length) return () => { };

        const handleSnapshot = (querySnapshot) => {
            setIsLoading(true);
            const messages = querySnapshot.docs.map((doc) => {
                const member = members.find((m) => m.id === doc.data().senderId);
                return {
                    ...doc.data(),
                    id: doc.id,
                    senderName: member?.name || 'None',
                };
            });
            const processedMessages = processMessagesToRender(messages);
            setMessages(processedMessages);
            setIsLoading(false);
        }

        const unsubscribe = onSnapshot(collection(db, 'chats', chatId, 'messages'), handleSnapshot);

        return () => unsubscribe();
    }, [chatId, members, processMessagesToRender]);


    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <ChatRightHeader chat={chat} members={members} />
            <div style={styles.messageListContainer}>
                {
                    isLoading ?
                        <Box display='flex' height='100%' justifyContent='center' alignItems='center'>
                            <CircularProgress />
                        </Box>
                        : <MessageList
                            className="message-list"
                            lockable
                            toBottomHeight={'100%'}
                            dataSource={messages}
                            onTitleClick={handleTitleClick}
                        />
                }
            </div>

            <div style={{ display: 'flex', marginTop: '8px', padding: '0 1rem' }}>
                <TextField
                    sx={{ flex: 1, marginRight: '8px' }}
                    autofocus
                    multiline
                    placeholder={t('Type here...')}
                    id="send-message"
                    label={t('Send message')}
                    variant="outlined"
                    value={inputMessage}
                    onChange={(event) => setInputMessage(event?.target?.value)}
                    onKeyDown={handleKeyDown}
                />

                <Button variant="contained" onClick={handleSend} disabled={isLoading}>
                    {t('Send')}
                </Button>
            </div>
        </div>
    );
}



ChatRightHeader.propTypes = {
    chat: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        callInfos: PropTypes.shape({
            id: PropTypes.string,
            start_at: PropTypes.any
        })
    }),
    members: PropTypes.array
};
function ChatRightHeader({ chat, members }) {
    const client = useStreamVideoClient();
    const chatCall = useChatCall(chat.id);

    const [callMenuAnchor, setCallMenuAnchor] = useState(null);
    const callMenuOpen = Boolean(callMenuAnchor);
    const [membersDialogOpen, setMembersDialogOpen] = useState(false);

    const [schedulingDialogOpen, setSchedulingDialogOpen] = useState(false);


    const handleCallMenuClose = () => setCallMenuAnchor(null);
    const handleOpenScheduleCallClick = () => setSchedulingDialogOpen(true);
    const onScheduleDialogClose = () => {
        setSchedulingDialogOpen(false);
        handleCallMenuClose();
    }

    const handleStartVideoCallClick = () => {
        const _callId = uuidv4();
        const call = client.call('default', _callId);

        call.getOrCreate({
            ring: true,
            data: {
                members: members.map((m) => ({ user_id: m.id })),
                custom: {
                    name: "Video call",
                    chatId: chat.id,
                    chatName: chat.name
                }
            }
        })
            .catch((error) => {
                console.error("Error starting call", error);

                if (error.code === 4) {
                    upsertUsers(members.map((m) => ({ id: m.id, name: m.name, image: m.image })))
                        .then(() => {
                            console.log("ChatRightHeader | Users upserted");
                            handleStartVideoCallClick();
                        })
                        .catch((error) => console.error("Error upserting users", error));
                }
            });
        handleCallMenuClose();
    }

    const handleScheduleCallClick = (scheduledAt) => {
        const _callId = uuidv4();
        const call = client.call('default', _callId);
        console.log("ChatRightHeader | scheduledAt", scheduledAt);

        call.getOrCreate({
            data: {

                starts_at: scheduledAt,
                members: members.map((m) => ({ user_id: m.id })),
                custom: {
                    name: "Scheduled call",
                    chatId: chat.id,
                    chatName: chat.name
                }
            }
        }).catch((error) => console.error("Error scheduling call", error));
    }

    const cannotCreateCall = Boolean(chatCall);

    return (
        <>
            <div style={styles.header}>
                <div style={styles.chatNameContainer}>
                    <Grow in key={chat?.name}>
                        <h2 style={styles.chatName}>{chat?.name}</h2>
                    </Grow>
                </div>

                <IconButton
                    id="call-menu"
                    aria-label="call"
                    aria-controls={callMenuOpen ? 'call-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={callMenuOpen ? 'true' : undefined}
                    onClick={(event) => setCallMenuAnchor(event.currentTarget)}
                >
                    <VideoCallOutlined />
                </IconButton>
                <Menu
                    id="call-menu"
                    anchorEl={callMenuAnchor}
                    open={callMenuOpen}
                    onClose={handleCallMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'call-menu',
                    }}
                >
                    <MenuItem onClick={handleStartVideoCallClick} disabled={cannotCreateCall}>Start video call</MenuItem>
                    <MenuItem onClick={handleOpenScheduleCallClick} disabled={cannotCreateCall}>Schedule video call</MenuItem>
                    <CallScheduleDialog open={schedulingDialogOpen} onClose={onScheduleDialogClose} onSubmit={handleScheduleCallClick} />
                </Menu>

                <IconButton aria-label="call" onClick={() => setMembersDialogOpen(true)}>
                    <PeopleOutline />
                </IconButton>
                <MembersManagmentDialog
                    chatId={chat?.id}
                    open={membersDialogOpen}
                    onClose={() => setMembersDialogOpen(false)}
                    currentMembers={members}
                />

                <IconButton aria-label="manage-people">
                    <MoreVert />
                </IconButton>
            </div>
            <CallBar call={chatCall} />
        </>
    );
}



CallScheduleDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func
};
function CallScheduleDialog({ open, onClose, onSubmit }) {
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [time, setTime] = useState(dayjs().add(1, 'hour').format('HH:mm'));
    const [error, setError] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        const scheduledAt = dayjs(`${date} ${time}`).toDate();
        if (scheduledAt < new Date()) {
            setError('Scheduled time must be in the future');
            return;
        }
        onSubmit(scheduledAt);
        onClose();
    }

    const timeDifference = dayjs().to(dayjs(`${date} ${time}`));

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}
            maxWidth="xs"
        >
            <DialogTitle>Schedule video call</DialogTitle>
            <DialogContent>
                {error && <Alert sx={{ marginBottom: "2rem" }} severity="error" onClose={() => setError(null)}>{error}</Alert>}
                <Box display="flex" gap="2rem" marginBottom="2rem">
                    <TextField
                        id="date"
                        type="date"
                        value={date}
                        onChange={(event) => setDate(event.target.value)}
                        variant='standard'
                        fullWidth

                    />
                    <TextField
                        id="time"
                        type="time"
                        value={time}
                        onChange={(event) => setTime(event.target.value)}
                        variant='standard'
                        fullWidth
                    />
                </Box>
                <DialogContentText>
                    Members will be notified of the scheduled call {timeDifference.includes("ago") ? '_' : timeDifference}.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit">Schedule</Button>
            </DialogActions>
        </Dialog>
    );
}


MembersManagmentDialog.propTypes = {
    chatId: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    currentMembers: PropTypes.array
};
function MembersManagmentDialog({ chatId, currentMembers, open, onClose }) {
    const [members, setMembers] = useState(currentMembers);
    const [isLoading, setIsLoading] = useState(false);
    const [snackData, setSnackData] = useState({ open: false, message: "" });

    const handleAddMember = (newMember) => {
        const m = members.find(_m => _m.name === newMember.name && _m.role === newMember.role);
        if (!m) {
            setMembers([...members, newMember]);
        }
    };

    const removeMember = (memberId) => {
        setMembers(members.filter(m => m.id !== memberId));
    };

    const handleCancel = () => {
        setMembers(currentMembers);
        onClose();
    };

    const handleSave = () => {
        setIsLoading(true);
        const chatRef = doc(db, 'chats', chatId);
        updateDoc(chatRef, {
            members: members.map((m) => m.id)
        })
            .then(() => {
                setSnackData({ open: true, message: "Members updated" });
                onClose();
            })
            .catch((error) => {
                console.error("Error updating members", error);
                setSnackData({ open: true, message: "Error updating members" });
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setMembers(currentMembers);
    }, [currentMembers]);

    const selectedIds = members.map((m) => m.id);
    const noUpdate = !_.isEqual(members, currentMembers);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
        >
            <Snackbar
                open={snackData.open}
                autoHideDuration={5000}
                onClose={() => setSnackData({ open: false, message: "" })}
                message={snackData.message}
            />
            <DialogTitle>Manage members</DialogTitle>
            <DialogContent>
                <DialogContentText >
                    Add or remove members from the chat
                </DialogContentText>
                <UserSearch size="small" style={{ marginTop: '.5rem' }} selectedUserIds={selectedIds} onOptionSelect={handleAddMember} />
                <Box marginTop="1rem">
                    <Typography variant="subtitle2">Members</Typography>
                    <Paper style={{ maxHeight: 500, overflow: 'auto' }}>
                        <List>
                            {
                                members.map((member) => (
                                    <ListItem key={member.id} secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={() => removeMember(member.id)}>
                                            <Delete />
                                        </IconButton>
                                    }>
                                        <ListItemAvatar>
                                            <Avatar src={member.image} alt={member.name} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={member.name}
                                            secondary={member.role}
                                        />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Paper>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={handleCancel}>Cancel</Button>
                <Button disabled={!noUpdate || isLoading} onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

const styles = {
    container: {
        height: '100%',
        position: 'relative'
    },
    flex: {
        height: 'calc(100vh - 116px)',
        width: '100%',
        position: 'absolute',
        display: 'flex',
    },
    messages: {
        position: 'relative',
        scrollbarWidth: 'none',
        paddingBottom: '2rem',
        height: '100%',
        flex: 2
    },
    conversationList: {
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        flex: 1
    },
    chatListContainer: {
        display: 'flex',
        alignItems: 'row',
        position: 'absolute',
        width: '100%',
        height: '100%',
        marginRight: 8,
        backgroundColor: '#ededed',
    },
    chatList: {
        height: '100M'
    },
    messageListContainer: {
        flex: 1,
        overflow: 'auto',
        backgroundColor: '#f5f5f5',
    },
    header: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '.5rem 1rem',
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        gap: '1rem'
    },
    chatNameContainer: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    chatName: {
        fontSize: '1rem',
        margin: 0,
        padding: 0,
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '22rem'
    }
}

export default ChatsPage;