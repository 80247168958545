import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import {
  markOneNotificationsAsRead,
  markAllNotificationsAsRead,
  useFetchUserNotifications,
} from '../../../services/notifications_service';
import UserContext from '../../../contexts/UserContext';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { user } = useContext(UserContext);

  const { notifications } = useFetchUserNotifications(user);

  const [totalUnRead, setTotalUnread] = useState(0);

  const [open, setOpen] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    setTotalUnread(notifications.filter((item) => item.is_read === false).length);
  }, [notifications]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    markAllNotificationsAsRead(user, notifications);
  };

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{t('Notifications')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('new_notifications').replace(':nb', totalUnRead)}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title={t('Mark all as read')}>
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {totalUnRead > 0 && (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  {t('New')}
                </ListSubheader>
              }
            >
              {notifications
                .filter((notification) => !notification.is_read)
                .map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    notificationId={notification.id}
                    onClick={handleClose}
                  />
                ))}
            </List>
          )}

          <List
            disablePadding
            subheader={
              totalUnRead > 0 && notifications.length - totalUnRead !== 0 ? (
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  {t('Before that')}
                </ListSubheader>
              ) : undefined
            }
          >
            {notifications
              .filter((notification) => notification.is_read)
              .map((notification) => (
                <NotificationItem key={notification.id} notification={notification} onClick={handleClose} />
              ))}
          </List>
        </Scrollbar>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    created_at: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    is_read: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
    link: PropTypes.string,
  }),
};

function NotificationItem({ notification, onClick, notificationId }) {
  const { avatar, title } = useRenderContent(notification);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleClick = () => {
    markOneNotificationsAsRead(user, notificationId);
    navigate(notification.link);
    onClick();
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.is_read && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={handleClick}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.created_at)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function useRenderContent(notification) {
  const { t, i18n } = useTranslation();
  const { type } = notification;

  const createTitleComponent = (title, message) => (
    <Typography variant="subtitle2">
      {title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {i18n.language === 'en' ? noCase(message) : message}
      </Typography>
    </Typography>
  );

  if (type === 'new_session') {
    const title = t('title_new_session');
    const message = t('msg_new_session');

    return {
      avatar: <img alt={title} src="/assets/icons/ic_notification_mail.svg" />,
      title: createTitleComponent(title, message),
    };
  }

  if (type === 'new_message') {
    const title = t('title_new_messages');
    const message = t('msg_new_messages').replace(':name', notification.data.chat_name);

    return {
      avatar: <img alt={title} src="/assets/icons/ic_notification_mail.svg" />,
      title: createTitleComponent(title, message),
    };
  }

  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title: createTitleComponent('', ''),
  };
}
