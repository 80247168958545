import { collection, getDocs, query, Timestamp, doc, updateDoc, setDoc, getDoc, where } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebaseConfig";
import { sendNewMessageNotifications } from "./notifications_service";


export async function getChatDetails(chatId) {
    const chatSnapshot = await getDoc(doc(db, 'chats', chatId));

    if (!chatSnapshot.exists) {
        return null;
    }

    return {
        id: chatId,
        ...chatSnapshot.data(),
    };
}

export async function getUserChats(userId) {
    const chatsSnapshot = await getDocs(query(
        collection(db, 'chats'),
        where("members", "array-contains", userId),
    ));

    const chatsPromise = Promise.all(chatsSnapshot.docs.map(async (snapshot) => ({
            id: snapshot.id,
            ...snapshot.data()
        }))).then((chats) => chats
            .filter(chat => chat !== null)
            .sort((a, b) => a.lastUpdate - b.lastUpdate));

    return chatsPromise;
}


export async function sendMessage(message, chat, senderId, receivers) {
    const chatId = chat.id;
    const chatRef = doc(db, 'chats', chatId);
    const messageId = uuidv4();

    await updateDoc(chatRef, {
        lastMessage: message,
        lastUpdate: Timestamp.now()
    });

    await setDoc(doc(db, 'chats', chatId, 'messages', messageId), {
        createdAt: Timestamp.now(),
        message,
        senderId,
        id: messageId
    });

    sendNewMessageNotifications(chat, receivers, senderId);
}